.bg-gold {
  background-color: #FDF6ED;;
}

.bg-blue {
  background-color: #d0dffd;
}

.bg-white {
  background-color: white;
}

.bg-fade-yellow {
  background-color: #cac7a6;
}
