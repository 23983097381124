.w-3rem {
  width: 3rem;
}

.w-30 {
  width: 30%;
}

.w-45 {
  width: 45%;
}

.w-10 {
  width: 10%;
}

.w-16rem {
  width: 16rem;
}

.w-23-5rem {
  width: 23.5rem;
}

.w-20rem {
  width: 20rem;
}
