.sidebar-toggle {
  left: -2rem;
  position: absolute;
  top: 1rem;
  border: 1px solid #dbdddf;
  width: 2rem;
  height: 3rem;
  text-align: center;
  margin: 0;
  padding: 0;
  min-height: 3rem;
  min-width: 0;
  background-color: #0080601a;
  z-index: 100;

  &:hover {
    background-color: #008060;
    border: 1px solid #008060;
    color: white;
  }

  &.is-expand {
    left: -2.75rem;
    @media (max-width: 991px) {
      left: 0rem
    }
  }
}

#sidebarMenu:has( + main .sidebar-toggle:hover) {
  //box-shadow: inset -2px 0 0 #008060;
}
