.table.table-bordered {
  border-collapse: separate;

  th {
    border: 1px solid #dfe3e8;
  }
  th, td {
    border-spacing: 1.5em;
    &.w-m100px {
      min-width: 100px;
    }
    &.w-m200px {
      min-width: 200px;
    }
  }
}
