#product-stock {
  .table {
    table-layout: fixed;
    th.w-100px {
      min-width: 100px;
    };
    th.w-200px, td.w-200px {
      min-width: 200px;
    }
  }

  .text-saturday {
    color: dodgerblue;
  }
  .text-sunday {
    color: #DE3618;
  }
  .text-holiday {
    color: deeppink;
  }

  form input[type=number] {
    padding: 0;
  }
}
