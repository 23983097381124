@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "~bootstrap/dist/css/bootstrap.min";
@import "bootstrap-polaris/scss/main";
@import "@yaireo/tagify/src/tagify.scss";
@import '~sweetalert2/dist/sweetalert2.min';
@import "boostrap-extend/index";
@import "pages";
@import "~flatpickr/dist/themes/airbnb";
@import "~flatpickr/dist/plugins/monthSelect/style";

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
* Sidebar
*/

.sidebar {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
* Navbar
*/

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

/*
 * Main
 */

// Header
.page-header {
  .navbar {
    padding-right: 0;
    padding-left: 0;

    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// Card
.card {
  .card-header-tabs {
    .card-header-tab {
      & > a {
        padding-right: 2rem;
        padding-left: 2rem;

        &.active {
          border-color: #008060;
        }
      }
    }
  }

  .card-body {
    .table {
      td {
        vertical-align: middle;
      }
      &.table-list-with-thumbnail {
        .img-thumbnail {
          max-width: 60px;
        }
        svg.img-thumbnail {
          fill: #333333;
        }
      }
    }
  }
}

// Card - Image upload
.card {
  .card-body .js-drop-uploader-drop-area {
    position: relative;
    margin-right: -10px;
    min-height: 200px;

    .js-drop-uploader-thumbnail {
      width: calc(25% - 10px);
      height: calc(25% - 10px);
      float: left;
      margin: 0 10px 10px 0;
      position: relative;
      border: 1px $gray-300 solid;
      border-radius: 5px;

      .loading {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, .6);
        .polaris-spinner {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }

      .check {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,0) 20%, rgba(0,0,0,0.8) 100%);
        -webkit-transition: opacity 0.2s;
        -moz-transition: opacity 0.2s;
        -ms-transition: opacity 0.2s;
        -o-transition: opacity 0.2s;
        transition: opacity 0.2s;

        .polaris-check {
          top: 7px;
          left: 12px;
        }
      }

      &:hover .check,
      &.selected .check {
        opacity: 1;
      }
      &.progress .check {
        opacity: 0;
      }

      .thumbnail-image {
        width: 100%;
        padding-top: 100%;
        position: relative;
        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width:auto;
          height:auto;
          max-width:100%;
          max-height:100%;
        }
      }

      &:first-child {
        width: calc(50% - 10px);
        height: calc(50% - 10px);
        margin-bottom: 9px;
      }

      &.progress {
        .loading {
          display: block;
        }
        .thumbnail-image img {
          opacity: 0;
        }
      }
    }

    .js-drop-uoloader-fascade {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      border: 2px $gray-500 dashed;
      border-radius: 3px;
      & > div {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        font-weight: bold;
      }
    }

    &.no-images {
      .js-drop-uoloader-fascade {
        display: block;
      }
    }
  }
}

/*
 * File input(in Modal)
 */
.modal-body {
  .custom-file {
    height: auto;
    &-input {
      height: 4rem;
    }
    &-label {
      height: 4rem;
    }
  }
}

/*
 * Progress(in Modal)
 */
.modal-content {
  .js-import-modal-progress {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .9);

    .progress {
      position: absolute;
      top: 40%;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }
}

/*
 * Media
 */

.media {
  .media-figure {
    width: 60px;
    height: 60px;
    background: $gray-300;
    text-align: center;
    border-radius: 3px;
    margin-right: 16px;
    color: $gray-600;

    svg {
      width: 40px;
      height: 40px;
      margin-top: 10px;
    }
  }
}

/*
 * Preference
 */

.preference_link {
  display: block;
  border-radius: 3px;

  &:hover {
    opacity: 0.8;
    background: $gray-200;
  }

  .media .media-figure {
    width: 40px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .media .media-body {
    p {
      font-size: 1.4rem;
    }
  }
}

/*
 * Form
 */

.invalid-feedback {
  display: block;
  font-size: 1.5rem;
}

// Polaris
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #{'hsl(165deg 65% 21%)'};
}

.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary:disabled:hover, .btn-primary:disabled:focus, .btn-primary:disabled.focus, .btn-primary:disabled:active, .btn-primary:disabled.active {
  color: #fff;
  background-color: #{'hsl(165deg 65% 21%)'};
  background-image: none;
  border-color: #{'hsl(165deg 65% 21%)'};
  box-shadow: none;
}

input.no-arrow::-webkit-outer-spin-button,
input.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  &.no-arrow {
    -moz-appearance: textfield;
  }
}

.swal2-popup {
  font-size: 1.5rem;
}

// collapse tmp
#collapseExample {
  ul {
    list-style: none;
    margin-left: -20px;
  }
}

// disabled checkbox
input[type=checkbox][disabled]{
  outline:1px solid gray !important; // or whatever
}

input:invalid, textarea:invalid {
  border: 3px solid red;
}
input:invalid:focus, textarea:invalid:focus {
  //background-image: linear-gradient(pink, lightgreen);
  border: 3px solid red;
}

.img-thumbnail {
  display: block;
  margin: auto;
  width: 50px;
}
