.tabs {
  .tab-buttons {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    .tab {
      display: inline-block;
      box-shadow: 0 0 0 1px rgb(69 79 91 / 5%), 0 1px 3px 0 rgb(69 79 91 / 15%);
      border-radius: 0.5px;
      &.is-active {
        a {
          border-bottom-color: transparent;
          background: #fff;
        }
      }
      a {
        display: block;
        padding: 1rem 1.5rem;
        background: #eee;
        border: 1px solid #ddd;
        text-align: center;
        color: #000;
        text-decoration: none;
      }
    }
  }
}

.tab-content {
  background-color: white;
  padding: 1rem;
  box-shadow: 0 0 0 1px rgb(69 79 91 / 5%), 0 1px 3px 0 rgb(69 79 91 / 15%);
}

.nav-tabs .nav-link {
  padding: .75rem;
  background-color: #ffffff;
  border-color: #ddd #ddd #f4f6f8;
  border-bottom: 1px solid #ddd;
  color: #454f5b;
  &.active {
    background-color: #ffffff !important;
    border-bottom-color: #fff;
    &:hover, &:focus {
      border-bottom-color: #fff;
    }
  }
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #ddd;
}
